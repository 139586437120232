<template>
  <v-btn class="pl-0 pr-2" color="white" depressed small v-on:click="click"
    ><v-icon :class="options.class">{{ options.icon }}</v-icon>
    <span :class="options.titleClass" v-html="$te(label) ? $t(label) : label" />
  </v-btn>
</template>

<script>
export default {
  name: "LinkButton",
  props: {
    options: { type: Object, required: true, default: () => ({}) },
    label: { type: String, default: "" }
  },
  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>
